.header-section {
    background-color: lightgray;
    background-image: url(../../images/paris-black.jpg);
    background-size:cover;
    width: 100%;
    min-height: 500px;
    display:flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    color:white;}